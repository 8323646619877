/*========== LOGIN ACTIONS ============= */

export const login = (data) => ({
      type: 'LOGIN',
      payload: data,
    });

export const setLoginData = (data) => ({
  type: 'SET_LOGIN_DATA',
  payload: data,
});

export const toggleLogin = () => ({
  type: 'TOGGLE_LOGIN',
});

export const isloginDisabled = () => ({
  type: 'IS_LOGIN_DISABLED',
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data
});

/*========== PAGE LOADER ACTIONS ============= */

export const setLoader = (data) => ({
  type: 'SET_LOADER',
  payload: data,
});


/*========== KYC ACTIONS ============= */

export const getKYC = () => ({
  type: 'GET_KYC'
});

export const setKYC = (data) => ({
  type: 'SET_KYC',
  payload: data
});

export const updateKYC = (data) => ({
  type: 'UPDATE_KYC',
  payload: data
});

export const toggleStatusModal = (data) => ({
  type: 'TOGGLE_STATUS_MODAL',
  payload: data
});