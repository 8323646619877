import moment from "moment";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { Button } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import React, { Component, Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import "./index.css";
import { updateKYC, getKYC, toggleStatusModal } from "../../store/actions/Auth";

class ApproveKYC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kycData: [],
      publicAddress: "",
      kycStatus: true,
    };
  }

  async componentDidMount() {
    await this.props.getKYC();
  }

  componentWillReceiveProps({ kycData }) {
    this.setState({ kycData });
  }

  handleEditChange = (e) => this.setState({ kycStatus: e.target.value });

  copiedAddress = () => EventBus.publish("success", "Wallet Address Copied");

  verifyKYC = (publicAddress, open) => {
    this.setState({ publicAddress });
    this.props.toggleStatusModal(open);
  };

  submitApproval = () => {
    let { kycStatus, publicAddress } = this.state;
    this.props.updateKYC({ kycStatus, publicAddress });
  };

  render() {
    let { isStatusModal } = this.props;
    let { kycData, kycStatus } = this.state;

    const columns = [
      {
        id: "verify",
        Header: "Verify",
        accessor: (kycData) =>
          kycData["kycStatus"] ? (
            "Verified"
          ) : (
            <Button
              variant="outlined"
              className="mx-1 view-btn verifyBtn"
              onClick={() => this.verifyKYC(kycData["publicAddress"], true)}
            >
              Verify
            </Button>
          ),
        filterable: false,
      },
      // {
      //     id: 'publicAddress',
      //     Header: 'Wallet Address',
      //     accessor: kycData => kycData['publicAddress'] ? kycData['publicAddress'] : '-',
      // },
      {
        id: "publicAddress",
        Header: "Wallet Address",
        accessor: (kycData) =>
          kycData["publicAddress"] ? (
            <CopyToClipboard
              text={kycData["publicAddress"]}
              onCopy={this.copiedAddress}
            >
              <a className="public-address">{`${kycData[
                "publicAddress"
              ].substring(0, 8) +
                "....." +
                kycData["publicAddress"].substring(
                  34,
                  kycData["publicAddress"].length
                )}`}</a>
            </CopyToClipboard>
          ) : (
            "-"
          ),
      },
      {
        id: "Name",
        Header: "Name",
        accessor: (kycData) => (kycData["Name"] ? kycData["Name"] : "-"),
      },
      {
        id: "country",
        Header: "Country",
        accessor: (kycData) => (kycData["country"] ? kycData["country"] : "-"),
      },
      {
        id: "taxId",
        Header: "Tax Id",
        accessor: (kycData) => (kycData["taxId"] ? kycData["taxId"] : "-"),
      },
      {
        id: "socialSecurityNo",
        Header: "Social Security",
        accessor: (kycData) =>
          kycData["socialSecurityNo"] ? kycData["socialSecurityNo"] : "-",
      },
    ];
    console.log("************kycData", kycData);
    return (
      <div className="content">
        <div className="main-container mintedNFT">
          <div className="edit-add">
            <div className="mb-3">
              <h1>Pending KYC</h1>
              {/* <Button variant="outlined" className="add-btn" onClick={}>Approve Drop</Button> */}
            </div>
            <Fragment>
              <div className="main-container-head mb-3">
                <ReactTable
                  className="table"
                  data={this.state.kycData}
                  resolveData={(kycData) => kycData.map((row) => row)}
                  columns={columns}
                  minRows={20}
                  filterable={true}
                />
              </div>
            </Fragment>
          </div>
        </div>
        {/* ---------------VIEW MODAL--------------- */}

        <Modal
          isOpen={isStatusModal}
          toggle={() => this.props.toggleStatusModal(false)}
          className="main-modal drop-modal"
        >
          <ModalHeader toggle={() => this.props.toggleStatusModal(false)}>
            <div className="modal-logo">
              <img
                src={require("../../assets/img/logo.png")}
                alt="modal-logo"
              />
            </div>
            <div className="drop-modal-title text-center">
              <p className="">Update Drop</p>
            </div>
            <div className="drop-modal-line">
              <hr />
            </div>
          </ModalHeader>
          <ModalBody className="modal-body drop-modal-body">
            <div className="row">
              <div className="col-12">
                <ValidatorForm onSubmit={this.submitApproval}>
                  <TextValidator
                    select
                    native
                    fullWidth
                    type="boolean"
                    name="kycStatus"
                    value={kycStatus}
                    variant="outlined"
                    id="standard-full-width"
                    className="form-input-field"
                    onChange={this.handleEditChange}
                    label={
                      <label>
                        Approval Status <span>*</span>
                      </label>
                    }
                  >
                    <MenuItem value="true">Approved</MenuItem>
                    <MenuItem value="false">Discard</MenuItem>
                  </TextValidator>
                </ValidatorForm>
              </div>
              <div className="col-12 mt-5 d-flex justify-content-around">
                <Button
                  className="cancel-btn col-4"
                  type="button"
                  onClick={() => this.props.toggleStatusModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="add-btn col-4"
                  type="button"
                  onClick={this.submitApproval}
                >
                  Submit
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateKYC,
  getKYC,
  toggleStatusModal,
};

const mapStateToProps = ({ Auth }) => {
  let { kycData, isStatusModal } = Auth;
  return { kycData, isStatusModal };
};
export default connect(mapStateToProps, mapDispatchToProps)(ApproveKYC);
