
import Approved from "./views/Approve/index.js";
import TXDetails from "./views/TXDetails/index.js";
import Blacklist from "./views/Blacklist/index.js";
import ApproveKYC from "./views/ApproveKYC/index.js";


var routes = [
  {
    layout: "/home",
    path: "/",
    component: ApproveKYC,
    hidden: true,
  },
  {
    layout: "/home",
    path: "/KycApprove",
    name: "Approve KYC",
    component: ApproveKYC,
    imgUrl: "/images/approved.svg",
  },
  {
    layout: "/home",
    path: "/Approve",
    name: "Approve",
    component: Approved,
    imgUrl: "/images/approved.svg",
  },
  {
    layout: "/home",
    path: "/Blacklist",
    name: "Blacklist",
    component: Blacklist,
    imgUrl: "/images/blacklist.svg",
  },
  {
    layout: "/home",
    path: "/TXDetails",
    name: "Transactions Details",
    component: TXDetails,
    imgUrl: "/images/details.svg",
  },
];

export default routes;